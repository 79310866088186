<template>
  <div :style="{ height: `${spinnerSize}px`, width: `${spinnerSize}px` }">
    <div
      class="h-full w-full animate-spin-fast rounded-full border-2 border-current"
      style="border-right-color: transparent; border-top-color: transparent"
    />
  </div>
</template>

<script>
export default {
  props: {
    big: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 16,
    },
  },
  computed: {
    spinnerSize() {
      if (this.big) {
        return 40;
      }

      return this.size;
    },
  },
};
</script>
